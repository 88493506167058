import dayjs from "dayjs";
import { GENERAL_CONSTANTS } from "src/config/constants/general-constants";
import { getDateWithoutTimezoneChange } from "./formatTime";
import { EVENT_TYPES_CONSTANTS } from "../config/constants/event-types-constants";
import { RESERVATION_CONSTANTS } from "src/config/constants/reservation-constants";

const formatOutputVariants = ["calendar", "modal"];

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
const formatAreas = (areas, formatOutput) => {
  if (!areas || areas?.length === 0) {
    return [];
  }

  if (!formatOutputVariants.includes(formatOutput)) {
    console.warn(`formatOutput must be one of ${formatOutputVariants}`);
    return [];
  }

  let response = null;

  if (formatOutput === "calendar") {
    const resArray = [];

    areas.forEach((area) => {
      if (area.tables.length === 0) {
        return;
      }

      const tables = area.tables.map((table) => {
        return {
          id: table.id,
          title: `${table.name} (${table.capacity})`,
          capacity: table.capacity,
          area: area.name,
        };
      });

      resArray.push(...tables);
    });

    response = resArray;
  }

  if (formatOutput === "modal") {
    const resObj = {
      areas: [],
      tables: [],
    };

    areas.forEach((area) => {
      if (area.tables.length === 0) {
        return;
      }

      const tables = area.tables.map((table) => {
        return {
          id: table.id,
          title: `${table.name} (${table.capacity})`,
          capacity: table.capacity,
          areaId: area.id,
        };
      });

      resObj.areas.push({
        value: area.id,
        label: area.name,
      });

      resObj.tables.push(...tables);
    });

    response = resObj;
  }

  return response;
};

const formatReservations = (areas) => {
  if (!areas || areas?.length === 0) {
    return [];
  }

  const formattedRes = [];

  areas.forEach((area) => {
    if (!area.tables || area.tables.length === 0) {
      return;
    }

    area.tables.forEach((table) => {
      if (!table.bookedSessions || table.bookedSessions.length === 0) {
        return;
      }
      const tableSessions = [];

      table.bookedSessions.forEach((session) => {
        // const index = formattedRes.findIndex((res) => res.id === session.id);
        // console.log(index);
        // if (index !== -1) {
        //   formattedRes[index] = {
        //     ...formattedRes[index],
        //     table: [...formattedRes[index].table, table.id],
        //   };
        // }

        const startDate = new Date(
          getDateWithoutTimezoneChange(session.bookedHour)
        );

        const endDate = dayjs(startDate)
          .add(session.duration, "minutes")
          .toDate();

        // if (index === -1) {
        tableSessions.push({
          id: session.id,
          persons: session.numberOfGuests,
          name: `${session.organizer?.firstName} ${session.organizer?.lastName}`,
          area: area.id,
          table: table.id,
          tableName: table.name,
          status: session.status,
          startDate: dayjs(startDate).format(),
          endDate: dayjs(endDate).format(),
          email: session.organizer?.account?.email,
          phone: session.organizer?.account?.phone,
          visitsCount: session.visitsCount,
        });
        // }
      });

      formattedRes.push(...tableSessions);
    });
  });

  const groupedReservations = new Map();

  formattedRes.forEach((reservation) => {
    if (!groupedReservations.has(reservation.id)) {
      groupedReservations.set(reservation.id, []);
    }

    groupedReservations.get(reservation.id).push(reservation);
  });

  groupedReservations.forEach((group, id) => {
    const tablesNames = group.map((reservation) => reservation.tableName);

    if (group.length > 1) {
      const tables = group.map((reservation) => reservation.table);

      group.forEach((reservation) => {
        reservation.multipleTables = true;
        reservation.tables = tables;
        reservation.tablesNames = tablesNames;
      });
    }
  });

  return Array.from(groupedReservations.values()).flat();
};

const formatBookingDuration = (duration) => {
  const hours = Math.floor(duration / 60);

  const minutes = duration % 60;

  const seconds = 0;

  // Returns 1:0:0 instead of 01:00:00
  return `${hours}:${minutes}:${seconds}`;
};

const formatBusinessHours = (hours) => {
  if (!hours || hours.length === 0) {
    return [];
  }

  // Helper function to get day index
  const dayIndex = (day) => {
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ].indexOf(day);
  };

  // Initialize the result array and temporary storage for current grouping
  let businessHours = [];
  let currentGroup = null;

  hours.forEach((day) => {
    const index = dayIndex(day.name);
    // Check if we can add the day to the current group
    if (
      currentGroup &&
      currentGroup.startTime === day.openAt &&
      currentGroup.endTime === day.closeAt
    ) {
      currentGroup.daysOfWeek.push(index);
    } else {
      // Finish the current group and start a new one
      if (currentGroup) businessHours.push(currentGroup);
      currentGroup = {
        daysOfWeek: [index],
        startTime: day.openAt,
        // Currently, our hour selectors only go to 23:00, so if we want to close at 00:00, we need to select it, but we should feed it as 24:00 to the calendar
        endTime: day.closeAt === "00:00" ? "24:00" : day.closeAt,
      };
    }
  });

  if (currentGroup) businessHours.push(currentGroup);

  return businessHours;
};

const getInterDayEndingHour = (startHour,endHour) => {
   //hardcoding a random date here as dayjs cant compare hours alone
    const startDateComparer = dayjs (`12-12-2012 ${startHour}`);
    const endDateComparer = dayjs(`12-12-2012 ${endHour}`);
    const [hours,minutes] = endHour.split(":");

    if( endDateComparer.diff(startDateComparer) <= 0 ){
        
        const newHours = Number(hours) + 24;
        return `${newHours}:${minutes}`;

    }

    return`${endHour}`;
}

export const getDurationBetweenTimeDates = (rawStartTimeDate,rawEndTimeDate) => {
    const startTimeDate = dayjs(rawStartTimeDate);
    const endTimeDate = dayjs(rawEndTimeDate);

    const isEndTimeAtMidnight =
      endTimeDate?.get("minutes") === 0 && endTimeDate?.get("hours") === 0;

    if(isEndTimeAtMidnight){
        return 24*60 - (startTimeDate.get('hours') * 60 + startTimeDate.get("minutes"));
    }

    return endTimeDate.diff(startTimeDate,'minutes');
}

const getHoursAndMinutes = (date) => dayjs(date).format('HH:mm');

const calculateMinutes = (time) =>
    parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);

const checkDateWithinBusinessHours = (date,businessHours,referenceDate) => {
    const dayOfWeek = referenceDate.getDay(); // Using JavaScript's getDay() method directly
    let currentTimeMinutes = date.getHours() * 60 + date.getMinutes();

    const businessHoursFormatted = businessHours.map((businessHour) => ({...businessHour,endTime:getInterDayEndingHour(businessHour.startTime,businessHour.endTime)}))
    const dayHours = businessHoursFormatted.find((hours) =>
      hours.daysOfWeek.includes(dayOfWeek)
    );
    const [currentDayStartHours,currentDayStartMinutes] = dayHours.startTime.split(':');
    const referenceDateAtStart = dayjs(referenceDate).set('hours',currentDayStartHours).set('minutes',currentDayStartMinutes).set('seconds','00').toDate();
    if (!dayHours) return false;

    const startTimeMinutes = calculateMinutes(dayHours.startTime);
    const endTimeMinutes =
      dayHours.endTime === "00:00"
        ? 24 * 60
        : calculateMinutes(dayHours.endTime);

    if(calculateMinutes(getHoursAndMinutes(date)) - calculateMinutes(getHoursAndMinutes(referenceDateAtStart)) < 0){
        currentTimeMinutes += 24*60;
    }

    return (
      currentTimeMinutes >= startTimeMinutes &&
      currentTimeMinutes <= endTimeMinutes
    );
  };

const isWithinBusinessHours = (startDate, endDate, businessHours,referenceDate) => {
  if (!startDate || !endDate || !businessHours || businessHours.length === 0)
    return false;

  return (
    checkDateWithinBusinessHours(startDate,businessHours,referenceDate)&&
    checkDateWithinBusinessHours(endDate,businessHours,referenceDate)
  );
};

const getSlotWidthBasedOnBookingInterval = (bookingInterval) => {
  return (
    (GENERAL_CONSTANTS.ONE_HOUR_CALENDAR_SLOT_WIDTH * bookingInterval) / 60
  );
};

const isEventNonPermanentAndNoOpeningHours = (
  openingHours,
  startDate,
  endDate,
  isPermanent
) => {
  if (
    startDate !== null &&
    endDate !== null &&
    dayjs(startDate).isBefore(dayjs(endDate)) &&
    openingHours?.length === 0 &&
    isPermanent === false
  ) {
    return EVENT_TYPES_CONSTANTS.COMBINATIONS
      .NO_OPENING_HOURS_AND_NON_PERMANENT;
  }
  if (openingHours?.length > 0 && isPermanent === true) {
    return EVENT_TYPES_CONSTANTS.COMBINATIONS.OPENING_HOURS_AND_PERMANENT;
  }
};

const createOpeningHoursFromStartEndTime = (startDate, endDate) => {
  if (!startDate || !endDate) return [];

  const startDateNoTimezone = getDateWithoutTimezoneChange(startDate);
  const endDateNoTimezone = getDateWithoutTimezoneChange(endDate);

  const startDayOfWeek = dayjs(startDateNoTimezone).day(); // Get day of the week for startDate
  let endDayOfWeek = dayjs(endDateNoTimezone).day(); // Get day of the week for endDate
  const startTime = dayjs(startDateNoTimezone).format("HH:mm"); // Get start time from startDate
  const endTime = dayjs(endDateNoTimezone).format("HH:mm"); // Get end time from endDate

  if (endDayOfWeek < startDayOfWeek) {
    endDayOfWeek += 7; // Add 7 days to endDayOfWeek
  }

  const openingHours = []; // Initialize opening hours array

  for (let i = startDayOfWeek; i <= endDayOfWeek; i++) {
    //hardcode locale en since the day names we're checking are hardcoded in en in dayIndex func 
    const day = dayjs().day(i).locale("en").format(RESERVATION_CONSTANTS.DAY_FORMAT); // Get day name from dayjs

    // Push opening hours object to array
    openingHours.push({
      name: day,
      openAt: startTime,
      closeAt: endTime,
    });
  }

  return openingHours;
};

export {
  formatAreas,
  formatReservations,
  formatBookingDuration,
  formatBusinessHours,
  isWithinBusinessHours,
  getSlotWidthBasedOnBookingInterval,
  isEventNonPermanentAndNoOpeningHours,
  createOpeningHoursFromStartEndTime,
  getInterDayEndingHour
};
