import { Button } from "@mui/material";
import {
  RESERVATIONS_STATUSES_MODAL_CREATE,
  RESERVATION_CONSTANTS,
  RESERVATION_STATUSES_MODAL_BLOCKED,
  RESERVATION_STATUSES_MODAL_CANCELED,
  RESERVATION_STATUSES_MODAL_CONFIRMED, RESERVATION_STATUSES_MODAL_WAITING_FOR_PAYMENT,
} from "../config/constants/reservation-constants";
import { LoadingButton } from "@mui/lab";
import { getDurationBetweenTimeDates } from "./bookingModule";

export const mapReservationStatusToColor = (status) => {
  switch (status) {
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.CONFIRMED:
      return "success";
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.PENDING:
      return "warning";
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.BLOCKED:
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.DENIED:
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_USER:
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_VENUE:
      return "error";
    default:
      return "info";
  }
};

export const mapReservationStatusToTranslation = (status) => {
  if (!status) {
    return "";
  }

  switch (status) {
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.CONFIRMED:
      return "bookings.confirmed";
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.PENDING:
      return "bookings.pending";
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.WAITING_FOR_PAYMENT:
      return "bookings.waiting_for_payment";
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_USER:
      return "bookings.cancel_by_user";
    case RESERVATIONS_STATUSES_MODAL_CREATE.CANCELLED:
      return "bookings.canceled";
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_VENUE:
      return "bookings.cancel_by_venue";
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.DELETED_BY_VENUE:
      return "bookings.deleted_by_venue";
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.BLOCKED:
      return "bookings.blocked";
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.DENIED:
      return "bookings.denied";
    default:
      return "bookings.noStatus";
  }
};

export const formatMinutesToHours = (minutes) => {
  if (!minutes) {
    return "";
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (remainingMinutes === 0) {
    return `${hours}h`;
  }

  return `${hours}:${remainingMinutes.toString().padStart(2, "0")}h`;
};

export const formatAreasFromApiToBookingCardFormat = (areas) => {
  return areas.map((area) => {
    return {
      label: area.name,
      value: area.id,
    };
  });
};

export const getStatusesToUse = (initialStatus, isEdit) => {
  if (initialStatus === RESERVATION_CONSTANTS.RESERVATION_STATUS.BLOCKED) {
    return RESERVATION_STATUSES_MODAL_BLOCKED;
  }

  if (
    initialStatus === RESERVATION_CONSTANTS.RESERVATION_STATUS.CONFIRMED &&
    !isEdit
  ) {
    return RESERVATION_STATUSES_MODAL_CONFIRMED;
  } else if (
    initialStatus === RESERVATION_CONSTANTS.RESERVATION_STATUS.CONFIRMED &&
    isEdit
  ) {
    return RESERVATIONS_STATUSES_MODAL_CREATE;
  }

  if (initialStatus === RESERVATION_CONSTANTS.RESERVATION_STATUS.PENDING) {
    return RESERVATIONS_STATUSES_MODAL_CREATE;
  }

  if (initialStatus === RESERVATION_CONSTANTS.RESERVATION_STATUS.WAITING_FOR_PAYMENT) {
    return RESERVATION_STATUSES_MODAL_WAITING_FOR_PAYMENT;
  }

  if (
    initialStatus ===
      RESERVATION_CONSTANTS.RESERVATION_STATUS.DELETED_BY_VENUE ||
    RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_USER ||
    RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_VENUE ||
    RESERVATION_CONSTANTS.RESERVATION_STATUS.DENIED
  ) {
    return RESERVATION_STATUSES_MODAL_CANCELED;
  }

  return RESERVATION_CONSTANTS.RESERVATION_CONSTANTS;
};

export const getHexaColor = (hexColor, opacity) => {
  // Convert opacity percentage to a two-digit hexadecimal value
  const alpha = Math.floor(opacity * 255)
    .toString(16)
    .padStart(2, "0");
  return `${hexColor}${alpha}`;
};

export const findAvailableTables = (tables, guestsW, areaId) => {
  const availableTables = tables.filter(
    (table) => table.status === "available" && table.areaId === areaId
  );

  // Sort available tables by their absolute difference from guestsW in ascending order
  availableTables.sort(
    (a, b) => Math.abs(a.capacity - guestsW) - Math.abs(b.capacity - guestsW)
  );

  let totalCapacity = 0;
  const selectedTables = [];

  // Iterate through the available tables and select appropriate combinations
  for (const table of availableTables) {
    if (totalCapacity >= guestsW) {
      break; // Break loop if all guests are seated
    }
    if (totalCapacity + table.capacity <= guestsW) {
      selectedTables.push(table);
      totalCapacity += table.capacity;
    }
  }

  // Check if all guests have been seated
  if (totalCapacity < guestsW) {
    return []; // Return an empty array if not all guests can be seated
  }

  return selectedTables;
};

export const fetchDataAvailabilityModal = async (
  dayjs,
  endTimeW,
  startTimeW,
  dateW,
  getDataAvailability,
  guestsW,
  fTime,
  areaValue,
  setDatesToBeDisabled,
  areaAndTablesData,
  setAreaAndTablesData,
  autoSetArea,
  hasPreselectedArea,
  setValue,
  setAutoSetArea,
  isEdit,
  initialDate
) => {
  const bookingDuration = getDurationBetweenTimeDates(startTimeW,endTimeW);

  const formattedDate = dayjs(dateW).format(RESERVATION_CONSTANTS.YEAR_FORMAT);

  // We get availability data
  const resp = await getDataAvailability({
    numberOfGuests: Number(guestsW),
    bookedDate: formattedDate,
    duration: bookingDuration,
    bookedHour: fTime(startTimeW),
    areaId: areaValue?.value,
  });

  // Calculate disabled dates for calendar
  setDatesToBeDisabled((prev) => {
    const prevCopy = [...prev];

    resp?.dates?.forEach((date) => {
      const isDateAlreadyDisabled = prevCopy.find(
        (disabledDate) => disabledDate === date.name
      );

      if (isDateAlreadyDisabled && date.status === "available") {
        prevCopy.splice(prevCopy.indexOf(date.name), 1);
      } else if (date.status === "unavailable") {
        prevCopy.push(date.name);
      }
    });

    const filteredPrev = prevCopy.filter(
      (value, index) => prevCopy.indexOf(value) === index
    );

    if (!isEdit) {
      return filteredPrev;
    }

    const dateToCheckFor = dayjs(initialDate).format(
      RESERVATION_CONSTANTS.YEAR_FORMAT
    );

    return filteredPrev.filter((date) => date !== dateToCheckFor);
  });

  // Calculate new areas
  const newAreas = resp?.areas?.map((area) => {
    return {
      label: area.name,
      value: area.id,
    };
  });

  // Filter the tables that are not available
  const newTables = resp?.tables
    ?.map((table) => ({
      ...table,
      title: `${table.name} (${table.capacity})`,
    }))
    ?.filter((table) => table?.status !== "unavailable");

  setAreaAndTablesData({
    tables: newTables,
    areas: newAreas,
  });

  // Auto select first available area
  if (!autoSetArea && !hasPreselectedArea) {
    const firstArea = newAreas[0];
    setValue("area", firstArea);

    setAutoSetArea(true);
  }

  // Check if we have missing available areas
  // Only fetch next available date if the booking module is a restaurant
  if (
    (!resp?.areas?.length || !resp?.tables?.length) &&
    resp?.bookingModuleType === "RESTAURANT" &&
    !isEdit
  ) {
    let availability = resp;
    const selectedDate = availability?.dates?.find(
      (date) => date.name === formattedDate
    );

    // If the selected date is available, use it
    const firstAvailableDate =
      selectedDate.status === "available"
        ? formattedDate
        : availability?.dates?.find((date) => date.status === "available")
            ?.name;

    if (firstAvailableDate) {
      availability = await getDataAvailability({
        numberOfGuests: Number(guestsW),
        bookedDate: firstAvailableDate,
      });

      const firstAvailableTime = availability?.bookedHours[0];

      setValue("date", dayjs(firstAvailableDate).toDate());

      const newStartDate = dayjs(`${firstAvailableDate} ${firstAvailableTime}`);
      setValue("startTime", newStartDate);
      const minimumBookingDuration = Math.min(...availability?.durations);
      const newEndDate = newStartDate.add(minimumBookingDuration, "minute");
      setValue("endTime", newEndDate);
      setValue("area", {
        label: availability?.areas[0]?.name,
        value: availability?.areas[0]?.id,
      });
    }
  }
};

export const getButtonToUseInModal = (
  initialStatus,
  isEdit,
  translate,
  setBookingStatus,
  setBookingStatusStatusLoading,
  isSubmitting,
  setOpenDeleteConfirm,
  createBookingLoading,
  rescheduleBookingLoading,
  blockBookingIntervalLoading,
  onClose,
  handleApproveBooking,
  handleDeleteBooking,
  isAdmin,
) => {
  const deleteButton = <><Button
    size="large"
    color="error"
    variant="contained"
    onClick={() => {
      handleDeleteBooking();
    }}
  >
    {translate("bookings.delete")}
  </Button></>;
  switch (initialStatus) {
    // Case Canceled
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_USER:
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_VENUE:
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.DENIED:
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.DELETED_BY_VENUE:
      return deleteButton;

    case RESERVATION_CONSTANTS.RESERVATION_STATUS.WAITING_FOR_PAYMENT:
      if (isAdmin) 
        return deleteButton;
      else
        return false;

    // Case Pending
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.PENDING:
      return (
        <>
          <Button
            disabled={setBookingStatusStatusLoading || isSubmitting}
            onClick={() => {
              setOpenDeleteConfirm(true);
            }}
            size="large"
            variant="contained"
            color="error"
          >
            {translate("bookings.deny")}
          </Button>
          <Button
            size="large"
            variant="contained"
            color="success"
            onClick={() => {
              handleApproveBooking();
            }}
          >
            {translate("bookings.approve")}
          </Button>
        </>
      );

    // Case Confirmed
    case RESERVATION_CONSTANTS.RESERVATION_STATUS.CONFIRMED:
      if (isEdit) {
        return (
          <>
            <Button
              variant="contained"
              size="large"
              color="error"
              onClick={() => setOpenDeleteConfirm(true)}
            >
              {translate("bookings.deny")}
            </Button>
            <LoadingButton
              variant={"contained"}
              size="large"
              type="submit"
              color="success"
              loading={
                isSubmitting ||
                createBookingLoading ||
                rescheduleBookingLoading ||
                blockBookingIntervalLoading
              }
            >
              {translate("bookings.save")}
            </LoadingButton>
          </>
        );
      } else {
        return (
          <>
            <Button
              variant="outlined"
              size="large"
              sx={{
                border: (t) => `1px solid ${t.palette.grey[500]}`,
                color: (t) => t.palette.grey[500],
              }}
              onClick={() => onClose()}
            >
              {translate("bookings.cancel")}
            </Button>
            <LoadingButton
              variant={"contained"}
              size="large"
              type="submit"
              color="success"
              loading={
                isSubmitting ||
                createBookingLoading ||
                rescheduleBookingLoading ||
                blockBookingIntervalLoading
              }
            >
              {translate("bookings.save")}
            </LoadingButton>
          </>
        );
      }

    case RESERVATION_CONSTANTS.RESERVATION_STATUS.BLOCKED:
      if (isEdit) {
        return (
          <>
            <Button
              variant="contained"
              size="large"
              color="error"
              onClick={() => setOpenDeleteConfirm(true)}
            >
              {translate("bookings.delete")}
            </Button>
            <LoadingButton
              variant={"contained"}
              size="large"
              type="submit"
              loading={
                isSubmitting ||
                createBookingLoading ||
                rescheduleBookingLoading ||
                blockBookingIntervalLoading
              }
            >
              {translate("bookings.save")}
            </LoadingButton>
          </>
        );
      } else
        return (
          <>
            <Button
              variant="outlined"
              size="large"
              sx={{
                border: (t) => `1px solid ${t.palette.grey[500]}`,
                color: (t) => t.palette.grey[500],
              }}
              onClick={() => onClose()}
            >
              {translate("bookings.cancel")}
            </Button>
            <LoadingButton
              variant={"contained"}
              size="large"
              type="submit"
              loading={
                isSubmitting ||
                createBookingLoading ||
                rescheduleBookingLoading ||
                blockBookingIntervalLoading
              }
            >
              {translate("bookings.save")}
            </LoadingButton>
          </>
        );

    default:
      return null; // Return null if no case matches
  }
};

export const removeDuplicates = (array, key) => {
  const uniqueSet = new Set();
  const result = [];

  array.forEach((item) => {
    const propValue = item[key];
    if (!uniqueSet.has(propValue)) {
      uniqueSet.add(propValue);
      result.push(item);
    }
  });

  return Array.from(result);
};

export const numberToText = (num) => {
  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return "th";
  }

  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
