import { Box, Card, CardContent, Typography } from "@mui/material";
import ChipSingleSelect from "../../../../components/ChipSingleSelect";
import { formatMinutesToHours } from "src/utils/reservationFunctions";
import { RESERVATION_CONSTANTS } from "../../../../config/constants/reservation-constants";
import { BookingAccordionNoOptions } from "../listing-details/BookingAccordionNoOptions";
import { useLocales } from "../../../../locales";
import React, { useEffect, useState } from "react";
import { getBookingDays } from "./BookingDateUtils";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTheme } from "@emotion/react";
import GuestsIcon from 'src/assets/icons/booking/GuestsIcon';
import DateIcon from 'src/assets/icons/booking/DateIcon';
import LengthIcon from 'src/assets/icons/booking/LengthIcon';
import AreaIcon from 'src/assets/icons/booking/AreaIcon';
import TimeIcon from 'src/assets/icons/booking/TimeIcon';
import HoldingFeeIcon from 'src/assets/icons/booking/HoldingFeeIcon';

const guests = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const BookingDataCard = ({
  eventGeneralData,
  watch,
  setValue,
  trigger,
  getHeader,
  errors,
  availableDates,
  availableLength,
  availableAreas,
  availableHours,
  bookingCardVariant,
  loadingCheckAvailability,
  isInIframe,
  holdingFee,
  loadAvailability,
}) => {
  const { translate, currentLang } = useLocales();
  const theme = useTheme();
  const [bookingDays, setBookingDays] = useState([]);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  useEffect(() => {
    (async () => {
      const days = await getBookingDays(
        eventGeneralData,
        watch("date"),
        availableDates,
        currentLang.value
      );
      setBookingDays(days);
    })();
  }, [JSON.stringify(availableDates), watch("date")]);

  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {getHeader(
            translate("bookings.guests"),
            <GuestsIcon />,
            errors.guests
          )}
        </Box>
        <Box sx={{ marginLeft: 3.5 }}>
          <ChipSingleSelect
            singleRow={true}
            onChange={(val) => {
              setValue("guests", val);
              trigger("guests");
              loadAvailability("guests");
            }}
            items={guests}
            value={watch("guests")}
            rowOnSmScreen={true}
            isInIframe={isInIframe}
          />
        </Box>
      </>

      {bookingCardVariant ===
        RESERVATION_CONSTANTS.BOOKING_CARD_VARIANTS.FULL && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {getHeader(
                translate("bookings.date"),
                <DateIcon />,
                errors.date
              )}
            </Box>
            <Box sx={{ marginLeft: 3.5 }}>
              <ChipSingleSelect
                singleRow={true}
                onChange={(val) => {
                  if (val === 'OTHER') {
                    setIsCalendarVisible(true);
                  } else {
                    setIsCalendarVisible(false);
                    setValue("date", val);
                    trigger("date");
                    loadAvailability("date");
                  }
                }}
                items={bookingDays}
                value={watch("date")}
                rowOnSmScreen={true}
                isInIframe={isInIframe}
              />
            </Box>
            {isCalendarVisible && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  onChange={(date) => {
                    setValue("date", dayjs(date).format('YYYY-MM-DD'));
                    trigger("date");
                    loadAvailability("date");
                    setIsCalendarVisible(false);
                  }}
                  minDate={dayjs().startOf('month')}
                  maxDate={dayjs().add(1,'year').endOf('month')}
                  showToolbar={false}
                  shouldDisableDate={(date) => !availableDates.find(it => it.name === date.format(RESERVATION_CONSTANTS.YEAR_FORMAT))}
                  value={dayjs(watch("date"))}
                  name="date"
                  slotProps={{
                    DatePickerToolbar: { style: { display: "none" } },
                    nextIconButton: {
                      style: {
                        pointerEvents: loadingCheckAvailability ? "none" : "auto",
                      },
                    },
                    previousIconButton: {
                      style: {
                        pointerEvents: loadingCheckAvailability ? "none" : "auto",
                      },
                    },
                    day: {
                      style: {
                        fontWeight: "500",
                      },
                    },
                  }}
                  disablePast
                  disabled={loadingCheckAvailability}
                  sx={{
                    backgroundColor: "transparent",
                    "& .MuiPickersDay-root": {
                      "&.Mui-selected": {
                        color: "black",
                        backgroundColor: "white",
                      },
                      "&.Mui-disabled:not(.Mui-selected)": {
                        color: `${theme.palette.text.secondary}`,
                      },
                    },
                    "& .MuiButton-root": {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          </>
        )}

      {bookingCardVariant ===
        RESERVATION_CONSTANTS.BOOKING_CARD_VARIANTS.FULL && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {getHeader(
                translate("bookings.length"),
                <LengthIcon />,
                errors.length
              )}
            </Box>
            <Box sx={{ marginLeft: 3.5 }}>
              {availableLength.length > 0 ? (
                <ChipSingleSelect
                  singleRow={true}
                  rowOnSmScreen={true}
                  onChange={(val) => {
                    setValue("length", val);
                    trigger("length");
                    loadAvailability("length");
                  }}
                  items={availableLength.map((val) => ({
                    label: formatMinutesToHours(val),
                    value: val,
                  }))}
                  value={watch("length")}
                  isInIframe={isInIframe}
                />
              ) : (
                <BookingAccordionNoOptions
                  loadingCheckAvailability={loadingCheckAvailability}
                />
              )}
            </Box>
          </>
        )}

      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {getHeader(
            translate("bookings.area"),
            <AreaIcon />,
            errors.area
          )}
        </Box>
        <Box sx={{ marginLeft: 3.5 }}>
          {availableAreas.length > 0 ? (
            <ChipSingleSelect
              singleRow={true}
              rowOnSmScreen={true}
              onChange={(val) => {
                setValue("area", val);
                trigger("area");
                loadAvailability("area");
              }}
              items={availableAreas}
              value={watch("area")}
              isInIframe={isInIframe}
            />
          ) : (
            <BookingAccordionNoOptions
              loadingCheckAvailability={loadingCheckAvailability}
            />
          )}
        </Box>
      </>

      {bookingCardVariant ===
        RESERVATION_CONSTANTS.BOOKING_CARD_VARIANTS.FULL && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {getHeader(translate("bookings.time"), <TimeIcon />, errors.time)}
            </Box>
            <Box sx={{ marginLeft: 3.5 }}>
              {availableHours.length > 0 ? (
                <ChipSingleSelect
                  singleRow={true}
                  rowOnSmScreen={true}
                  onChange={(val) => {
                    setValue("time", val);
                    trigger("time");
                    loadAvailability("time");
                  }}
                  items={availableHours}
                  value={watch("time")}
                  isInIframe={isInIframe}
                />
              ) : (
                <BookingAccordionNoOptions
                  loadingCheckAvailability={loadingCheckAvailability}
                />
              )}
            </Box>
          </>
        )}
      {!!holdingFee && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {getHeader(translate("bookings.holdingFee"), <HoldingFeeIcon />, errors.time)}
          </Box>
          <Card raised sx={{
            marginLeft: 3.5,
            backgroundColor: theme.palette.background.neutral,
            maxWidth: 453,
          }}>
            <CardContent>
              <Typography variant="h4">
                {holdingFee} RON
              </Typography>

              <Typography variant="h6">
                {translate("bookings.howItWorks")}
              </Typography>
              <Typography variant="subtitle1">
                {translate("bookings.holdingFeeDisclaimer")}
              </Typography>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};


export default BookingDataCard;
